/**
 * Created by jerry on 2020/02/25.
 * 角色api
 */
import * as API from '@/api/index'

export default {
    // 角色列表
    getRole: params => {
        return API.POST('api/role/list', params)
    },
    //角色列表(不分页)
    getRoleAll: params => {
        return API.POST('api/role/permissionAll', params)
    },
    //新增角色
    createRole: params => {
        return API.POST('api/role/create', params)
    },
    //编辑角色
    updateRole: params => {
        return API.POST('api/role/update', params)
    },
    //角色菜单权限列表
    getRoleGet: params => {
        return API.POST('api/role/get', params)
    },
    //菜单权限列表
    getMenuPermission: params => {
        return API.POST('api/menuPermission/list', params)
    },
}
