/**
 * Created by jerry on 2020/02/14.
 * 组织架构api
 */
import * as API from '@/api/index'

export default {

    // 通讯录-员工列表
    getEmployee: params => {
        return API.POST('api/employee/empPermissionList', params)
    },
    //用户管理
    getEmployeeManage: params => {
        return API.POST('api/employeeManage/list', params)
    },
    // 通讯录-新增员工
    createEmployee: params => {
        return API.POST('api/employee/create', params)
    },
    // 通讯录-保存员工
    updateEmployee: params => {
        return API.POST('api/employee/update', params)
    },
    // 通讯录-批量更新员工职务
    updateEmployeeJobTypeBatch: params => {
        return API.POST('api/employee/updateEmployeeJobTypeBatch', params)
    },
    // 通讯录-批量更新员工部门
    updateEmployeeEntityBatch: params => {
        return API.POST('api/employee/updateEmployeeEntityBatch', params)
    },

    // 营业报表-消耗统计
    exportEmployeeExce: params => {
      return API.exportExcel('api/employee/excel', params)
    },
}