<template>
  <div class="content_body EmployeeEntityPermission" v-loading="loading">
    <div class="nav_header_1">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" size="small" @keyup.enter.native="handleSearch">
            <el-form-item label="员工">
              <el-input v-model="name" placeholder="输入员工编号或姓名" clearable @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="职务">
              <el-select v-model="JobID" placeholder="选择职务" clearable filterable :default-first-option="true" size="small" @change="handleSearch">
                <el-option v-for="item in jobTypeList" :key="item.ID" :label="item.JobName" :value="item.ID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="在职状态">
              <el-select v-model="State" placeholder="选择在职状态" clearable size="small" @change="handleSearch" @clear="handleSearch">
                <el-option label="在职" :value="true"></el-option>
                <el-option label="离职" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属部门">
              <treeselect v-model="EntityID" :options="entityList" :normalizer="normalizer" clearValueText noResultsText="无匹配数据" placeholder="选择所属部门" @input="handleSearch" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" ref="multipleTable" :data="tableData" tooltip-effect="light" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="ID" label="员工编号"></el-table-column>
        <el-table-column prop="Name" label="员工姓名"></el-table-column>
        <el-table-column prop="EntityName" label="所属单位" show-overflow-tooltip min-width="140px">
          <template slot-scope="scope">
            <span v-for="(item,index) in scope.row.Entity" :key="index">{{item.EntityName}}{{item.IsPrimaryEntity?"[主]":""}}{{scope.row.Entity.length==index+1?"":" ; "}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="JobName" label="职务" show-overflow-tooltip></el-table-column>
        <el-table-column prop="State" label="在职状态" show-overflow-tooltip :formatter="formatStatus"></el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="roleClick(scope.row)" v-prevent-click>角色分配</el-button>
            <el-button type="primary" size="small" @click="jurisdictionClick(scope.row)" v-prevent-click>权限范围</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page pad_10 dis_flex flex_x_between" v-if="paginations.total > 0">
        <div>
          <el-dropdown trigger="click" @command="dropdownClick">
            <el-button type="primary" size="small">
              批量设置
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">角色分配</el-dropdown-item>
              <el-dropdown-item command="2">权限范围</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="text_right">
          <el-pagination background @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
        </div>
      </div>
    </div>

    <!--角色弹窗-->
    <el-dialog :visible.sync="dialogVisible" width="40%">
      <span slot="title">
        <span class="font_18">角色分配</span>
        <span class="color_gray font_12 marlt_10">修改后将跳转登录页，需重新登录</span>
      </span>
      <el-row>
        <el-checkbox-group v-model="RoleID">
          <el-col :span="6" v-for="(item,index) in roleList" :key="index">
            <el-checkbox class="marbm_10" :label="item.ID">{{item.Name}}</el-checkbox>
          </el-col>
        </el-checkbox-group>
      </el-row>
      <!-- <div>
        <div class="marbm_15">
          <div>

            <el-checkbox-group v-model="RoleID">
              <el-checkbox
                class="marbm_10"
                v-for="(item,index) in roleList"
                :key="index"
                :label="item.ID"
              >{{item.Name}}</el-checkbox>
            
          </div>
        </div>
      </div>-->
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="saveRole" :loading="modalLoading" v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>

    <!--权限弹窗-->
    <el-dialog :visible.sync="jurisdictionVisible" width="40%">
      <span slot="title">
        <span class="font_18">权限范围</span>
        <span class="color_gray font_12 marlt_10">修改后将跳转登录页，需重新登录</span>
      </span>
      <div>
        <el-scrollbar class="el-scrollbar_height">
          <el-tree :data="jurisdictionList" show-checkbox node-key="ID" ref="tree" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :default-checked-keys="defaultCheckedKeys" :props="defaultProps" :default-expanded-keys="defaultExpandedKeys">
            <span slot-scope="{ data }">
              <span class="font_14">{{ data.EntityName }}</span>
              <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsStore">门店</el-tag>
              <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsWarehouse">仓库</el-tag>
            </span>
          </el-tree>
        </el-scrollbar>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="jurisdictionVisible = false" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="saveJurisdiction" :loading="modalLoading" v-prevent-click>保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import APIJob from "@/api/KHS/Entity/jobtype";
import APIEntity from "@/api/KHS/Entity/entity";
import APIEmployee from "@/api/KHS/Entity/employee";
import APIEmployeeRole from "@/api/KHS/Role/employeeRole";
import APIRole from "@/api/KHS/Role/role";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "EmployeeEntityPermission",
  components: { Treeselect },
  data() {
    return {
      loading: false,
      modalLoading: false,
      name: "",
      JobID: "", //职务
      State: true, //状态
      EntityID: null, //单位
      jobTypeList: [], //职务列表
      jobStatusList: [], //在职状态
      entityList: [],
      dialogVisible: false,
      jurisdictionVisible: false,
      tableData: [],
      RoleID: [], //选中的角色roleList
      roleList: [], //员工角色
      roleInitData: [], //角色初始化数据数据
      jurisdictionList: [], //员工权限
      roleEmployee: [], //员工权限回显
      defaultCheckedKeys: [],
      defaultExpandedKeys: [1],
      multipleSelection: [],
      EmployeeID: [],
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 12, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },

  methods: {
    //状态显示转换
    formatStatus: function (row) {
      return row.State ? "在职" : "离职";
    },
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },
    //职务列表
    jobTypeData: function () {
      var that = this;
      var params = {
        JobTypeName: "",
      };
      APIJob.getJobJobtypeAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.jobTypeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 所属单位
    entityData: function () {
      var that = this;
      APIEntity.getEntityAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function () {
      let that = this;
      that.loading = true;
      that.loading = true;
      var params = {
        SearchKey: that.name,
        JobID: that.JobID,
        State: that.State,
        EntityID: that.EntityID,
        PageNum: that.paginations.page,
      };
      APIEmployee.getEmployee(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },

    //初始化角色数据
    roleData: function () {
      var that = this;
      that.loading = true;
      var params = {
        RoleName: "",
      };
      APIRole.getRoleAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.roleInitData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 角色分配按钮事件
    roleClick: function (row) {
      var that = this;
      this.roleData();
      that.loading = true;
      that.dialogVisible = true;

      that.roleList = [];
      Object.assign(that.roleList, that.roleInitData);
      that.EmployeeID = row.ID.split(",");
      that.RoleID = [];
      var params = {
        EmployeeID: row.ID,
      };
      APIEmployeeRole.getEmployeeRole(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.RoleID = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 权限分配按钮事件
    jurisdictionClick: function (row) {
      var that = this;
      that.entityData();
      that.loading = true;
      that.EmployeeID = row.ID.split(",");
      that.jurisdictionVisible = true;
      that.defaultCheckedKeys = [];
      that.defaultExpandedKeys = [1];
      that.jurisdictionList = [];
      Object.assign(that.jurisdictionList, that.entityList);
      var params = {
        EmployeeID: row.ID,
      };
      APIEmployeeRole.employeeEntityPermission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeys = res.Data;
            that.defaultExpandedKeys = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 批量设置
    dropdownClick: function (index) {
      var that = this;
      if (that.multipleSelection.length == 0) {
        that.$message.error({
          message: "请选择员工",
          duration: 2000,
        });
        return false;
      }
      if (index == "1") {
        this.roleData();
        that.dialogVisible = true;
        that.RoleID = [];
        that.roleList = [];
        Object.assign(that.roleList, that.roleInitData);
      } else {
        that.entityData();
        that.jurisdictionVisible = true;
        that.defaultCheckedKeys = [];
        that.defaultExpandedKeys = [1];
        that.jurisdictionList = [];
        Object.assign(that.jurisdictionList, that.entityList);
      }
    },
    // 角色保存
    saveRole: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EmployeeID: that.EmployeeID,
        RoleID: that.RoleID,
      };
      APIEmployeeRole.updateUserRoleBatch(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "角色保存成功",
              duration: 2000,
            });
            if (this.$refs.multipleTable.selection.length > 0) {
              this.$refs.multipleTable.clearSelection();
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },

    // 权限保存
    saveJurisdiction: function () {
      var that = this;
      var EntityID = that.$refs.tree.getCheckedKeys();
      that.modalLoading = true;
      var params = {
        EmployeeID: that.EmployeeID,
        EntityID: EntityID,
      };
      APIEmployeeRole.updateUserEntityBatch(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "权限保存成功",
              duration: 2000,
            });
            if (this.$refs.multipleTable.selection.length > 0) {
              this.$refs.multipleTable.clearSelection();
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.jurisdictionVisible = false;
          that.modalLoading = false;
        });
    },
    // 右边表格选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
      var arr = [];
      val.forEach((item) => {
        arr.push(item.ID);
      });
      this.EmployeeID = Object.assign([], arr);
    },
  },
  mounted() {
    var that = this;
    that.jobTypeData();
    that.entityData();
    that.handleSearch();
    that.roleData();
  },
};
</script>

<style lang="scss">
.EmployeeEntityPermission {
  .nav_header_1 {
    padding: 15px 15px 0px 5px;
    .vue-treeselect__control {
      width: 250px;
      margin-right: 10px;
    }
  }

  .vue-treeselect__control {
    height: 32px;
    .vue-treeselect__value-container {
      min-width: 215px;
    }
  }

  .vue-treeselect__placeholder,
  .vue-treeselect__single-value {
    line-height: 32px;
  }
  .el-scrollbar_height {
    height: 60vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>
