/**
 * Created by jerry on 2020/02/25.
 * 用户权限api
 */
import * as API from '@/api/index'

export default {

  //用户角色列表
  getEmployeeRole: params => {
    return API.POST('api/employeeRole/list', params)
  },
  //用户权限范围列表
  employeeEntityPermission: params => {
    return API.POST('api/employeeEntityPermission/list', params)
  },
  //批量更新用户角色
  updateUserRoleBatch: params => {
    return API.POST('api/employeeRole/updateUserRoleBatch', params)
  },
  //批量更新用户权限范围
  updateUserEntityBatch: params => {
    return API.POST('api/employeeEntityPermission/updateUserEntityBatch', params)
  },
}
